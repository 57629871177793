import Vue from 'vue'
import vueResource from 'vue-resource'

Vue.use(vueResource)
var routes_data = [
  {
    name: 'dashboard',
    displayName: 'Dashboard',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-dashboard',
      authorize: ['ADMIN', 'RESELLER', 'OPERATOR', 'CMS_MANAGER', 'FINANCE', 'SALES', 'SUPPORT', 'ADVANCED_SUPPORT', 'HEPI_RESELLER', 'HEPI_OPERATOR','PROVIDER'],
      flag: [],
    },
  },
  // {
  //   name: 'credit',
  //   displayName: 'Credit',
  //   meta: {
  //     iconClass: 'fa fa-plus-circle',
  //     authorize: ['OPERATOR'],
  //     flag:[],
  //   },
  // },

  {
    name: 'food_op',
    displayName: 'Food',
    meta: {
      iconClass: 'entypo entypo-cup',
      authorize: ['HEPI_OPERATOR'],
      flag: [],
    },
  },
  {
    name: 'service_op',
    displayName: 'Service',
    meta: {
      iconClass: 'entypo entypo-phone',
      authorize: ['HEPI_OPERATOR'],
      flag: [],
    },
  },
  {
    name: 'room_op',
    displayName: 'Room',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-auth',
      authorize: ['HEPI_OPERATOR'],
      flag: [],
    },
  },
  {
    name: 'room_res',
    displayName: 'Room Reservation',
    meta: {
      iconClass: 'entypo entypo-clipboard',
      authorize: ['HEPI_OPERATOR'],
      flag: [],
    },
  },
  {
    name: 'location_op',
    displayName: 'Location',
    meta: {
      iconClass: 'entypo entypo-location',
      authorize: ['HEPI_OPERATOR'],
      flag: [],
    },
  },
  {
    name: 'hepi_settings',
    displayName: 'Hepi Settings',
    meta: {
      iconClass: 'entypo entypo-doc-text',
      authorize: ['HEPI_OPERATOR'],
      flag: [],
    },
  },
  {
    name: 'subscription',
    displayName: 'Subscription',
    meta: {
      iconClass: 'fa fa-users',
      authorize: ['ADMIN', 'RESELLER', 'OPERATOR', 'HEPI_OPERATOR', 'HEPI_RESELLER', 'SUPPORT','PROVIDER'],
      flag: [],
    },
  },
  {
    name: 'transaction',
    displayName: 'Transaction',
    meta: {
      iconClass: 'fa fa-exchange',
      authorize: ['RESELLER', 'OPERATOR', 'FINANCE', 'HEPI_OPERATOR'],
      flag: [],
    },
  },
  {
    name: 'provider',
    displayName: 'Bundles',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-components',
      authorize: ['ADMIN', 'RESELLER', 'OPERATOR', 'CMS_MANAGER', 'ADVANCED_SUPPORT', 'HEPI_OPERATOR', 'HEPI_RESELLER','SUPPORT'],
      flag: [],
    },
    disabled: false,
    children: [
      {
        name: 'service_type',
        displayName: 'Service Type',
        meta: {
          authorize: ['ADMIN'],
          flag: [],
        },
      },
      {
        name: 'service_category',
        displayName: 'Service Category',
        meta: {
          authorize: ['ADMIN', 'CMS_MANAGER'],
          flag: [],
        },
      },
      {
        name: 'service_provider',
        displayName: 'Service Provider',
        meta: {
          authorize: ['ADMIN', 'ADVANCED_SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'iptv_provider',
        displayName: 'IPTV Provider',
        meta: {
          authorize: ['ADMIN','SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'iptv_bundle',
        displayName: 'IPTV Bundle',
        meta: {
          authorize: ['ADMIN'],
          flag: [],
        },
      },
      {
        name: 'language',
        displayName: 'Language',
        meta: {
          authorize: ['ADMIN', 'CMS_MANAGER','SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'genres',
        displayName: 'Genres',
        meta: {
          authorize: ['ADMIN', 'CMS_MANAGER','SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'playstoreaccount',
        displayName: 'Play Account',
        meta: {
          authorize: ['ADMIN'],
          flag: [],
        },
      },
      {
        name: 'package',
        displayName: 'Packages',
        meta: {
          authorize: ['ADMIN', 'RESELLER', 'OPERATOR', 'HEPI_OPERATOR', 'HEPI_RESELLER','SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'bundle',
        displayName: 'Bundles',
        meta: {
          authorize: ['ADMIN', 'RESELLER', 'OPERATOR', 'HEPI_OPERATOR', 'HEPI_RESELLER','SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'reseller_custom',
        displayName: 'Custom Bundle',
        meta: {
          authorize: ['RESELLER', 'HEPI_RESELLER'],
          flag: ['enable_reseller_bundle_creation'],
        },
      },
      {
        name: 'content',
        displayName: 'Content',
        meta: {
          authorize: ['ADMIN', 'CMS_MANAGER',"SUPPORT"],
          flag: [],
        },
      },
    ],
  },
  {
    name: 'setting',
    displayName: 'Settings',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-ui-elements',
      authorize: ['ADMIN', 'RESELLER', 'OPERATOR', 'SUPPORT', 'ADVANCED_SUPPORT', 'HEPI_RESELLER','PROVIDER'],
      flag: [],
    },
    disabled: false,
    children: [
      {
        name: 'org',
        displayName: 'Organization',
        meta: {
          authorize: ['ADMIN', 'RESELLER', 'HEPI_RESELLER','SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'user',
        displayName: 'User',
        meta: {
          authorize: ['ADMIN', 'RESELLER', 'HEPI_RESELLER','SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'operator_setting',
        displayName: 'Operator Settings',
        meta: {
          authorize: ['ADMIN', 'RESELLER', 'SUPPORT', 'ADVANCED_SUPPORT', 'HEPI_RESELLER'],
          flag: [],
        },
      },
      {
        name: 'app_setting',
        displayName: 'App Setting',
        meta: {
          authorize: ['ADMIN','SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'vendor',
        displayName: 'Vendor',
        meta: {
          authorize: ['ADMIN','SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'inventory',
        displayName: 'Inventory',
        meta: {
          authorize: ['ADMIN', 'SUPPORT', 'ADVANCED_SUPPORT', 'RESELLER', 'HEPI_RESELLER','PROVIDER'],
          flag: [],
        },
      },
      {
        name: 'support_setting',
        displayName: 'Support Setting',
        meta: {
          authorize: ['ADMIN', 'RESELLER', 'OPERATOR', 'HEPI_OPERATOR','SUPPORT'],
          flag: ['create_org_flag'],
        },
      },
      {
        name: 'admin_setting',
        displayName: 'Admin Setting',
        meta: {
          authorize: ['ADMIN'],
          flag: [],
        },
      },
      {
        name: 'bill_setting',
        displayName: 'Bill Setting',
        meta: {
          authorize: ['ADMIN'],
          flag: [],
        },
      },
      {
        name: 'report',
        displayName: 'Report',
        meta: {
          authorize: ['ADMIN'],
          flag: [''],
        },
      },
      {
        name: 'external_app',
        displayName: 'External App',
        meta: {
          authorize: ['ADMIN','SUPPORT'],
          flag: [],
        },
      },
    ],
  },
  {
    name: 'catalog',
    displayName: 'Catalog',
    meta: {
      iconClass: 'vuestic-iconset vuestic-iconset-auth',
      authorize: ['ADMIN', 'SUPPORT', 'ADVANCED_SUPPORT'],
      flag: [],
    },
    disabled: false,
    children: [
      {
        name: 'food',
        displayName: 'Food',
        meta: {
          authorize: ['ADMIN', 'SUPPORT', 'ADVANCED_SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'service',
        displayName: 'Service',
        meta: {
          authorize: ['ADMIN', 'SUPPORT', 'ADVANCED_SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'location',
        displayName: 'Location',
        meta: {
          authorize: ['ADMIN', 'SUPPORT', 'ADVANCED_SUPPORT'],
          flag: [],
        },
      },
    ],
  },

  {
    name: 'reports',
    displayName: 'Reports',
    meta: {
      iconClass: 'fa fa-file-text-o',
      authorize: ['CMS_MANAGER'],
      flag: [],
    },
    disabled: false,
    children: [
      {
        name: 'zee_report',
        displayName: 'Zee Report',
        meta: {
          authorize: ['CMS_MANAGER'],
          flag: [],
        },
      },
    ],
  },
  {
    name: 'direct_play',
    displayName: 'Direct Play',
    meta: {
      iconClass: 'fa fa-play',
      authorize: ['ADMIN'],
      flag: [],
    },
  },
  // {
  //   name: 'yt',
  //   displayName: 'YT',
  //   meta: {
  //     iconClass: 'vuestic-iconset vuestic-iconset-files',
  //     authorize: ['ADMIN'],
  //     flag: [],
  //   },
  // },
  {
    name: 'advertisement',
    displayName: 'Advertisement',
    meta: {
      iconClass: 'fa fa-image',
      authorize: ['ADMIN', 'OPERATOR', 'HEPI_OPERATOR','SUPPORT'],
      flag: ['ad_provision'],
    },
    disabled: false,
    children: [
      {
        name: 'banner_images',
        displayName: 'Banner Images',
        meta: {
          authorize: ['ADMIN', 'CMS_MANAGER', 'OPERATOR','SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'notification',
        displayName: 'Notification',
        meta: {
          iconClass: 'fa fa-user',
          authorize: ['ADMIN', 'OPERATOR','SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'stb_advertisements',
        displayName: 'STB Advertisement',
        meta: {
          authorize: ['OPERATOR', 'ADMIN','SUPPORT'],
          flag: [],
        },
      },
      {
        name: 'stb_advertisement',
        displayName: 'Advertisement Slot',
        meta: {
          authorize: ['ADMIN'],
          flag: [],
        },
      },
      {
        name: 'allowed_app',
        displayName: 'Allowed App',
        meta: {
          authorize: ['ADMIN', 'OPERATOR', 'HEPI_OPERATOR','SUPPORT'],
          flag: ['allowed_app'],
        },
      },
    ],
  },
  {
    name: 'customer_organization',
    displayName: 'Customer Organization',
    meta: {
      iconClass: 'entypo entypo-plus-circled',
      authorize: ['FINANCE', 'SALES'],
      flag: [],
    },
  },
  {
    name: 'my_profile',
    displayName: 'My profile',
    meta: {
      iconClass: 'fa fa-user',
      authorize: ['ADMIN', 'RESELLER', 'OPERATOR', 'CMS_MANAGER', 'FINANCE', 'SALES', 'SUPPORT', 'ADVANCED_SUPPORT', 'HEPI_OPERATOR', 'HEPI_RESELLER','PROVIDER'],
      flag: [],
    },
    disabled: false,
    children: [
      {
        name: 'profile',
        displayName: 'Profile',
        meta: {
          authorize: ['ADMIN', 'RESELLER', 'OPERATOR', 'CMS_MANAGER', 'FINANCE', 'SALES', 'SUPPORT', 'ADVANCED_SUPPORT', 'HEPI_RESELLER', 'HEPI_OPERATOR','PROVIDER'],
          flag: [],
        },
      },
      {
        name: 'logout',
        displayName: 'Logout',
        meta: {
          authorize: ['ADMIN', 'RESELLER', 'OPERATOR', 'CMS_MANAGER', 'FINANCE', 'SALES', 'SUPPORT', 'ADVANCED_SUPPORT', 'HEPI_OPERATOR', 'HEPI_RESELLER','PROVIDER'],
          flag: [],
        },
      },
    ],
  }
]

export const getNavigationRoutes = () => {
  return routes_data
}

var navigationRoutes = {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: getNavigationRoutes(),
  routes_copy: routes_data,
}

export { navigationRoutes }
