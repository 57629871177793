import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '../components/auth/AuthLayout'
import AppLayout from '../components/admin/AppLayout'
import Login from '../components/auth/login/Login.vue'
import VueResource from 'vue-resource'
Vue.use(VueResource)
Vue.use(Router)
Vue.use(require('vue-cookies'))
//
const EmptyParentComponent = {
  template: '<router-view></router-view>',
}

function loginAuthGuard (to, from, next) {
  var accessToken = Vue.$cookies.get('x-access-token')
  if (accessToken) {
    Vue.$cookies.keys().forEach(cookie => Vue.$cookies.remove(cookie))
  }
  var isAuthenticated = localStorage.getItem('LoggedInUser')
  if (to.meta.isLoggedInCheck) {
    if (isAuthenticated) {
      next('/')
      return
    }
  }

  next()
}

function loginAuthcode (to, from, next) {
  next()
}

export default new Router({
  routes: [
    {
      path: '*',
      redirect: { name: 'login' },
    },
    {
      path: '/auth',
      component: AuthLayout,
      children: [
        {
          name: 'login',
          path: 'login',
          meta: {
            isLoggedInCheck: true,
          },
          beforeEnter: loginAuthGuard,
          component: Login,
        },
        {
          name: 'signup',
          path: 'signup',
          meta: {
            isLoggedInCheck: true,
          },
          beforeEnter: loginAuthGuard,
          component: () => import('../components/auth/signup/Signup.vue'),
        },
        {
          name: 'recover-password',
          path: 'recover-password',
          meta: {
            isLoggedInCheck: true,
          },
          beforeEnter: loginAuthGuard,
          component: () => import('../components/auth/recover-password/RecoverPassword.vue'),
        },
        {
          path: '',
          redirect: { name: 'login' },
        },
      ],
    },
    {
      path: '/404',
      component: EmptyParentComponent,
      children: [],
    },
    {
      name: 'Admin',
      path: '/admin',
      component: AppLayout,
      children: [
        {
          name: 'dashboard',
          path: 'dashboard',
          component: () => import('../components/dashboard/Dashboard.vue'),
          default: true,
        },
        {
          name: 'setting',
          path: 'setting',
          component: EmptyParentComponent,
          children: [{
            name: 'org',
            path: 'org',
            component: () => import('../components/org/Org.vue'),
            children: [],
          }, {
            name: 'users',
            path: 'users',
            component: () => import('../components/user/User.vue'),
          },
           {
            name: 'operator_setting',
            path: 'operator-setting',
            component: () => import('../components/operator_setting/OperatorSetting.vue'),
            children: [],
          }, {
            name: 'app_setting',
            path: 'app-setting',
            component: () => import('../components/app_setting/AppSetting.vue'),
            children: [],
          }, {
            name: 'vendor',
            path: 'vendor',
            component: () => import('../components/vendor/Vendor.vue'),
            children: [],
          }, {
            name: 'inventory',
            path: 'inventory',
            component: () => import('../components/inventory/Inventory.vue'),
            children: [],
          }, {
            name: 'admin_setting',
            path: 'admin-setting',
            component: () => import('../components/admin_setting/AdminSetting.vue'),
            children: [],
          }, {
            name: 'bill_setting',
            path: 'bill-setting',
            component: () => import('../components/bill_setting/BillSetting.vue'),
            children: [],
          }, {
            name: 'support_setting',
            path: 'support-setting',
            component: () => import('../components/support_setting/SupportSetting.vue'),
            children: [],
          }, {
            name: 'advertisement',
            path: 'advertisement',
            component: () => import('../components/advertisement/Advertisement.vue'),
            children: [],
          }, {
            name: 'advertisement_slot',
            path: 'advertisement_slot',
            component: () => import('../components/advertisement_slot/AdvertisementSlot.vue'),
            children: [],
          }, {
            name: 'operator_adslot',
            path: 'operator_adslot',
            component: () => import('../components/operator_adslot/OperatorAdSlot.vue'),
            children: [],
          }, {
            name: 'support_setting',
            path: 'support-setting',
            component: () => import('../components/support_setting/SupportSetting.vue'),
            children: [],
          }, {
            name: 'advertisement',
            path: 'advertisement',
            component: () => import('../components/advertisement/Advertisement.vue'),
            children: [],
          }, {
            name: 'report',
            path: 'report',
            component: () => import('../components/report/Report.vue'),
            children: [],
          },
          {
            name: 'external_app',
            path: 'external_app',
            component: () => import('../components/external_app/ExternalApp.vue'),
            children: [],
          }],
        },
        {
          name: 'provider',
          path: 'provider',
          component: EmptyParentComponent,
          children: [
            {
              name: 'service_provider',
              path: 'service_provider',
              component: () => import('../components/service_provider/ServiceProvider.vue'),
              children: [],
            }, {
              name: 'service_type',
              path: 'service_type',
              component: () => import('../components/service_type/ServiceType.vue'),
            }, {
              name: 'service_category',
              path: 'service_category',
              component: () => import('../components/service_category/ServiceCategory.vue'),
              children: [],
            }, {
              name: 'genres',
              path: 'genres',
              component: () => import('../components/genres/Genres.vue'),
              children: [],
            }, {
              name: 'playstoreaccount',
              path: 'playstoreaccount',
              component: () => import('../components/playstore_account/Playstore.vue'),
              children: [],
            }, {
              name: 'language',
              path: 'language',
              component: () => import('../components/language/Language.vue'),
              children: [],
            }, {
              name: 'package',
              path: 'package',
              component: () => import('../components/package/Package.vue'),
              children: [],
            }, {
              name: 'bundle',
              path: 'bundle',
              component: () => import('../components/bundle/Bundle.vue'),
              children: [],
            }, {
              name: 'content',
              path: 'content',
              component: () => import('../components/content/Content.vue'),
              children: [],
            }, {
              name: 'iptv_provider',
              path: 'iptv_provider',
              component: () => import('../components/iptv_provider/IptvProvider.vue'),
              children: [],
            }, {
              name: 'iptv_bundle',
              path: 'iptv_bundle',
              component: () => import('../components/iptv_bundle/IptvBundle.vue'),
              children: [],
            }],
        },
        {
          name: 'reports',
          path: 'reports',
          component: EmptyParentComponent,
          children: [
            {
              name: 'zee_report',
              path: 'zee_report',
              component: () => import('../components/zee_report/ZeeReport.vue'),
              children: [],
            }],
        },
        {
          name: 'profile',
          path: 'profile',
          component: () => import('../components/profile/Profile.vue'),
          children: [],
        },
        {
          name: 'direct_play',
          path: 'direct_play',
          component: () => import('../components/direct_play/DirectPlay.vue'),
          children: [],
        },
        {
          name: 'aboutus',
          path: 'about-us',
          component: () => import('../components/aboutus/Aboutus.vue'),
          children: [],
        },
        {
          name: 'language',
          path: 'language',
          component: () => import('../components/language/Language.vue'),
          children: [],
        },
        {
          name: 'setting',
          path: 'setting',
          component: () => import('../components/setting/Setting.vue'),
          children: [],
        },
        {
          name: 'user',
          path: 'user',
          component: () => import('../components/user/User.vue'),
          children: [],
        },

        {
          name: 'interface',
          path: 'interface',
          component: () => import('../components/interface/Interface.vue'),
          children: [],
        },
        {
          name: 'mso',
          path: 'mso',
          component: () => import('../components/mso/Mso.vue'),
          children: [],
        },
        {
          name: 'support_setting',
          path: 'support-setting',
          component: () => import('../components/support_setting/SupportSetting.vue'),
          children: [],
        },
        {
          name: 'backup',
          path: 'backup-report',
          component: () => import('../components/backup_report/BackupReport.vue'),
          children: [],
        },
        {
          name: 'upgrade',
          path: 'upgrade',
          component: () => import('../components/upgrade/Upgrade.vue'),
          children: [],
        },
        {
          name: 'advertisement_slot',
          path: 'advertisement-slot',
          component: () => import('../components/advertisement_slot/AdvertisementSlot.vue'),
          children: [],
        },
        {
          name: 'operator_adslot',
          path: 'operator-advertisement-slot',
          component: () => import('../components/operator_adslot/OperatorAdSlot.vue'),
          children: [],
        },
        {
          name: 'transaction',
          path: 'transaction',
          component: () => import('../components/transaction/Transaction.vue'),
          children: [],
        },
        {
          name: 'credit',
          path: 'credit',
          props: true,
          component: () => import('../components/credit/Credit.vue'),
          children: [],
        },
        {
          name: 'logout',
          path: 'logout',
          props: true,
          component: () => import('../components/logout/Logout.vue'),
          children: [],
        },
        {
          name: 'subscription',
          path: 'subscription',
          props: true,
          component: () => import('../components/subscription/Subscription.vue'),
          children: [],
        },
        {
          name: 'yt',
          path: 'yt',
          props: true,
          component: () => import('../components/yt/yt.vue'),
          children: [],
        },
        {
          name: 'advertisement',
          path: 'advertisement',
          component: EmptyParentComponent,
          children: [
            {
              name: 'banner_images',
              path: 'banner_images',
              props: true,
              component: () => import('../components/banner_images/BannerImages.vue'),
              children: [],
            },
            {
              name: 'notification',
              path: 'notification',
              component: () => import('../components/notification/Notification.vue'),
              children: [],
            },
            {
              name: 'stb_advertisement',
              path: 'stb_advertisement',
              props: true,
              component: () => import('../components/stb_advertisement/STB_Advertisement.vue'),
              children: [],
            },
            {
              name: 'stb_advertisements',
              path: 'stb_advertisements',
              component: () => import('../components/stb_advertisements/STB_Advertisements.vue'),
              children: [],
            },
            {
              name: 'allowed_app',
              path: 'allowed_app',
              component: () => import('../components/allowed_app/Allowed_app.vue'),
              children: [],
            },
              
            {
              name: 'customer_organization',
              path: 'customer_organization',
              props: true,
              component: () => import('../components/customer_organization/CustomerOrganization.vue'),
              children: [],
            },
            {
              name: 'reseller_custom',
              path: 'custom_bundle',
              props: true,
              component: () => import('../components/reseller_custom/ResellerCustom.vue'),
              children: [],
            },
                    {
              name: 'food',
              path: 'food_meta',
              props: true,
              component: () => import('../components/food/Food.vue'),
              children: [],
            },
            {
              name: 'service',
              path: 'service_meta',
              component: () => import('../components/service/Service.vue'),
              children: [],
            },
            {
              name: 'room_op',
              path: 'room',
              component: () => import('../components/room_op/RoomOp.vue'),
              children: []
            },
            {
              name: 'food_op',
              path: 'food',
              component: () => import('../components/food_op/FoodOp.vue'),
              children: []
            },
            {
              name: 'service_op',
              path: 'service',
              component: () => import('../components/service_op/ServiceOp.vue'),
              children: []
            },
            {
              name: 'room_res',
              path: 'room_reservation',
              component: () => import('../components/room_res/Roomres.vue'),
              children: []         
            },
            {
              name: 'hepi_settings',
              path: 'hepi_settings',
              component: () => import('../components/hepi_settings/HepiSettings.vue'),
              children: []         
            },
            {
              name: 'location',
              path: 'location/meta',
              component: () => import('../components/location/Location.vue'),
              children: []
            },
            {
              name: 'location_op',
              path: 'location',
              component: () => import('../components/location_op/LocationOp.vue'),
              children: []
            }
          ]},
          ]},  
          ],
          
})

