<template>
  <aside
    class="app-sidebar"
    :class="computedClass"
    :style="computedStyle"
  >
    <div class="app-sidebar__menu">
      <div v-if="!minimized" class="mb-4 icon">
        <img :src=image_url height="45px" width="40px" />
        <p class="text">{{domain_name}}</p>
      </div>
      <div v-else-if="minimized" class="mb-4 icon-mini">
        <img :src=image_url height="45px" width="40px" />
        <p class="text">{{domain_name}}</p>
      </div>
      <template v-for="(item, key) in items">
        <app-sidebar-link-group
          :key="key"
          :minimized="minimized"
          :icon="item.meta && item.meta.iconClass"
          v-if="item.children"
          :title="$t(item.displayName)"
          :children="item.children"
          :active-by-default="hasActiveByDefault(item)"
        >
          <app-sidebar-link
            v-for="(subMenuItem, key) in item.children"
            :key="key"
            :to="{ name: subMenuItem.name }"
            :title="$t(subMenuItem.displayName)"
          />
        </app-sidebar-link-group>
        <app-sidebar-link
          v-else
          :key="key"
          :minimized="minimized"
          :active-by-default="item.name === $route.name"
          :icon="item.meta && item.meta.iconClass"
          :to="{ name: item.name }"
          :title="$t(item.displayName)"
        />

      </template>
      <nav
        class="app-navbar">
        <div class="app-navbar__content row flex xs12">
          <div class="app-navbar__menu-container">
            <va-icon-menu
              class="app-navbar__menu"
              v-if="!minimized && !isTopBar"
              @click.native="changeStatus(minimized)"
              :color="contextConfig.invertedColor ? $themes.gray : 'white'"
            />
            <va-icon-menu-collapsed
              class="app-navbar__menu"
              v-if="minimized && !isTopBar"
              @click.native="changeStatus(minimized)"
              :color="contextConfig.invertedColor ? $themes.gray : 'white'"
            />
            <router-link
              class="app-navbar__logo mr-3"
              :to="{name:'dashboard'}"
              style="color: #2c82e0;">
              <!-- <div class="row">
            <img :src=image_url />
            <p class="app-navbar__menu_text">{{domain_name}}</p>
          </div> -->
            </router-link>
          </div>
          <!-- <va-button flat small icon="fa fa-pencil" @click="test()">btn </va-button>
      <va-button flat small icon="fa fa-pencil" @click="test()">btn </va-button>
      <va-button flat small icon="fa fa-pencil" @click="test()">btn </va-button>
      <va-button flat small icon="fa fa-pencil" @click="test()">btn </va-button>
      <va-button flat small icon="fa fa-pencil" @click="test()">btn </va-button> -->

          <!-- <label v-if="(role =='RESELLER')" style="color: black;" class="flex offset--lg8">Credits : {{amt}}</label>
          <app-navbar-actions
            class="app-navbar__actions flex lg1"
            :user-name="username"
            :is-top-bar.sync="isTopBarProxy"/> -->
        </div>
      </nav>
    </div>
  </aside>
</template>

<script>
import { navigationRoutes } from './NavigationRoutes'
import { sidebar } from '../../../i18n/sidebar.js'
import AppSidebarLink from './components/AppSidebarLink'
import AppSidebarLinkGroup from './components/AppSidebarLinkGroup'
import { ColorThemeMixin, colorShiftHsl } from '../../../services/vuestic-ui'
import config from '../../../i18n/en'
import image_config from '../../../i18n/image'
import Vue from 'vue'
import vueResource from 'vue-resource'
import VaIconMenu from '../../../iconset/VaIconMenu'
import VaIconMenuCollapsed from '../../../iconset/VaIconMenuCollapsed'
import AppNavbarActions from '../app-navbar/components/AppNavbarActions'

Vue.use(vueResource)

export default {
  name: 'app-sidebar',
  inject: ['contextConfig'],
  components: {
    AppSidebarLink,
    AppSidebarLinkGroup,
    VaIconMenu,
    VaIconMenuCollapsed,
    AppNavbarActions,
  },
  mixins: [ColorThemeMixin],
  props: {
    isTopBar: {
      type: Boolean,
      required: true,
    },
    minimized: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      default: 'secondary',
    },
  },
  created () {
    this.$themes.primary = '#474d57';
    this.$themes.info = '#474d57';
    var domain = location.host
    if (image_config[domain] !== undefined) {
      this.image_url = image_config[domain]['url']
      var host_name = image_config[domain]['name']
      this.domain_name = host_name
      document.title = host_name
    }
  },
  data () {
    return {
      items: sidebar(),
      domain_name: 'SKIE',
      image_url: 'https://app.skie.tv/portal_logo/skie.png',
    }
  },
  computed: {
    computedClass () {
      return {
        'app-sidebar--minimized': this.minimized,
      }
    },
    computedStyle () {
      return {
        backgroundColor: this.contextConfig.invertedColor ? 'white' : this.colorComputed,
      }
    },
    minimizedProxy: {
      get () {
        return this.minimized
      },
      set (minimized) {
        this.$emit('update:minimized', minimized)
      },
    },
    isTopBarProxy: {
      get () {
        return this.isTopBar
      },
      set (isTopBar) {
        this.$emit('update:isTopBar', isTopBar)
      },
    },
  },
  methods: {
    hasActiveByDefault (item) {
      return item.children.some(child => child.name === this.$route.name)
    },
    changeStatus (minimized) {
      this.minimized = !minimized
    },
  },
}

</script>

<style lang="scss">
.app-sidebar {
  overflow: auto;
  display: flex;
  max-height: 100%;
  flex: 0 0 16rem;

  @include media-breakpoint-down(sm) {
    flex: 0 0 100%;
  }

  &--minimized {
    flex: 0 0 3.25rem;
  }

  &__menu {
    margin-bottom: 0;
    padding-top: 2.5625rem;
    padding-bottom: 2.5rem;
    list-style: none;
    padding-left: 0;
    width: 100%;
  }
}

.icon {
  margin-left: 74px;
  margin-top: -20px;
}

.icon-mini {
  margin-left: 9px;
  margin-top: -20px;
}

.text {
  margin-top: -41px;
  margin-left: 44px;
}

$nav-border-side-width: 3.1875rem;

.app-navbar {
  transition: background-color 0.3s ease; /* sidebar's bg color transitions as well -> consistency */
  display: flex;
  padding: 1rem 1rem;
  z-index: 1;

  &__content {
    z-index: 1;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
    flex: 1 1 auto;
  }

  &__center {
    display: flex;
    margin-left: 3rem;
    justify-content: space-between;
    align-items: center;
  }

  &__text {
    color: $lighter-gray;
  }

  &__button {
    width: 10rem;
    margin: 0 0 0 1rem !important;
    font-weight: bold;

    .va-button__content__icon-left.fa-github {
      font-size: 1.5rem;
    }
  }

  &__menu {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    margin-right: 1rem;
  }

  &__menu-container {
    display: flex;
    flex-wrap: nowrap;
    height: 1.5rem;
  }

  &__menu_text {
    margin-left: 40px;
    margin-top: -27px;
  }

  &__logo {
    width: 1.9rem;
    height: 1.8rem;
    align-items: center;

    & * {
      max-height: 100%;
      max-width: 100%;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &__actions {
    justify-content: flex-end;
  }

  &__mailto-link:hover {
    filter: brightness(85%);
  }

  &__shape {
    transition: border-top-color 0.3s ease; /* sidebar's bg color transitions as well -> consistency */
    width: 33%;
    max-width: 467px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    border-top: 4.215rem solid transparent; // hardcoded size
    border-left: $nav-border-side-width solid transparent;
    border-right: $nav-border-side-width solid transparent;
    height: 0;
  }

  @include media-breakpoint-down(lg) {
    &__button {
      display: none !important;
    }
  }

  @include media-breakpoint-down(md) {
    &__center {
      display: none !important;
    }
  }

  @include media-breakpoint-down(sm) {
    &__content {
      align-items: flex-end;
    }

    &__actions {
      margin-top: 1.25rem;
      justify-content: space-between;
      width: 100%;
    }

    &__shape {
      display: none;
    }
  }
}
</style>
